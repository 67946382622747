import { useState, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import {
  OutlinedInput,
  InputAdornment,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Button,
  Typography,
  IconButton,
} from '@mui/material';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Collapse from '@mui/material/Collapse';
import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { APP_ROUTES } from 'routes/paths';
import { createRoute } from 'routes/utils';
import { projects as _projects } from '__mocks__/projects';

import { IProject, ProjectsTable } from 'pages/account/associatedProjects/ProjectsTable';
import { PortfolioInfo } from '../components/PortfolioInfo';
import styles from './AssociatedAccounts.module.scss';

interface IAccount {
  [key: string]: string | number | string[] | IProject[];
  id: number;
  name: string;
  accountFTE: number;
  deliveryLead: string;
  startDate: string;
  endDate: string;
  projects: IProject[];
}

interface IIndustry {
  name: string;
  id: number;
}

const accounts: IAccount[] = [
  {
    id: 23,
    name: 'Apple',
    accountFTE: 45,
    deliveryLead: 'Zoran Kovacevic',
    startDate: '01/01/2013',
    endDate: '01/01/2013',
    projects: _projects,
  },
  {
    id: 11,
    name: 'Account x',
    accountFTE: 55,
    deliveryLead: 'Zoran Kovacevic',
    startDate: '01/07/2013',
    endDate: '01/07/2013',
    projects: _projects,
  },
];

const industries: IIndustry[] = [
  {
    name: 'Apple',
    id: 45,
  },
  {
    name: 'Agriculture',
    id: 33,
  },
  {
    name: 'Automotive',
    id: 13,
  },
  {
    name: 'Aviation',
    id: 55,
  },
  {
    name: 'Biotechnology',
    id: 5,
  },
];

const csvConfig = mkConfig({
  fieldSeparator: ',',
  decimalSeparator: '.',
  useKeysAsHeaders: true,
  filename: 'Accounts',
  showTitle: true,
  title: 'My Generated Accounts for Portfolio',
});

export const AssociatedAccounts = () => {
  const navigate = useNavigate();
  const [industry, setIndustry] = useState('');
  const [status, setStatus] = useState('0');
  const [filterAccount, setFilterAccount] = useState('0');
  const [openAccountId, setOpenAccountId] = useState(0);

  const handleChangeIndustry = (event: SelectChangeEvent) => {
    setIndustry(event.target.value);
  };

  const handleChangeStatus = (event: SelectChangeEvent) => {
    setStatus(event.target.value);
  };

  const handleChangeAccount = (event: SelectChangeEvent) => {
    setFilterAccount(event.target.value);
  };

  const goToAccount = (id: string) => navigate(createRoute(APP_ROUTES.ACCOUNT, { id }));

  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(accounts);
    download(csvConfig)(csv);
  };

  return (
    <div>
      <PortfolioInfo
        name={'Portfolio 1'}
        numberOfAccounts={10}
        numberOfProjects={19}
        fte={120}
        keyPeopleInPortfolio={3}
      />
      <Box mt={'20px'} className={styles.filter}>
        <div>
          <OutlinedInput
            id='input-with-icon-adornment'
            placeholder='Search'
            size='small'
            startAdornment={
              <InputAdornment position='start'>
                <SearchIcon />
              </InputAdornment>
            }
          />
          <FormControl sx={{ ml: 1, minWidth: 120 }} size='small'>
            <InputLabel id='by-industry'>By Industry</InputLabel>
            <Select
              labelId='by-industry'
              id='by-industry-select'
              value={industry}
              label='By Industry'
              onChange={handleChangeIndustry}
              sx={{ backgroundColor: '#EFEFF2' }}
            >
              <MenuItem value=''>
                <em>---</em>
              </MenuItem>
              {industries.map((industry) => (
                <MenuItem value={industry.id} key={industry.id}>
                  {industry.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ ml: 1, minWidth: 120 }} size='small'>
            <InputLabel id='by-status'>By Status</InputLabel>
            <Select
              labelId='by-status'
              id='by-status-select'
              value={status}
              label='By Status'
              onChange={handleChangeStatus}
              sx={{ backgroundColor: '#EFEFF2' }}
            >
              <MenuItem value='0'>Pre-Active</MenuItem>
              <MenuItem value='1'>Active</MenuItem>
              <MenuItem value='2'>Inactive</MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{ ml: 1, minWidth: 120 }} size='small'>
            <InputLabel id='by-account'>By Account</InputLabel>
            <Select
              labelId='by-account'
              id='by-account-select'
              value={filterAccount}
              label='By Account'
              onChange={handleChangeAccount}
              sx={{ backgroundColor: '#EFEFF2' }}
            >
              <MenuItem value='0'>Adidas</MenuItem>
              <MenuItem value='1'>Costco Energy</MenuItem>
              <MenuItem value='2'>CVS Health</MenuItem>
              <MenuItem value='2'>Ethiqly</MenuItem>
              <MenuItem value='2'>Head</MenuItem>
            </Select>
          </FormControl>
        </div>
        <Button variant='contained' onClick={handleExportData}>
          Export to Google
        </Button>
      </Box>
      <Box mt={'22px'}>
        <Table sx={{ minWidth: 650 }} aria-label='simple table' style={{ tableLayout: 'fixed' }}>
          <TableHead>
            <TableRow>
              <TableCell>Account</TableCell>
              <TableCell>Account FTE's</TableCell>
              <TableCell>Start Date</TableCell>
              <TableCell>End Date</TableCell>
              <TableCell>Delivery Lead</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {accounts.map((account) => (
              <Fragment key={account.name}>
                <TableRow sx={{ backgroundColor: '#F0F0FF' }}>
                  <TableCell component='th' scope='row'>
                    <a
                      aria-hidden='true'
                      className={styles.a}
                      onClick={() => goToAccount(account.name)}
                    >
                      {account.name}
                    </a>
                  </TableCell>
                  <TableCell>{account.accountFTE}</TableCell>
                  <TableCell>{account.startDate}</TableCell>
                  <TableCell>{account.endDate}</TableCell>
                  <TableCell>
                    <Box display='flex' alignItems='center' justifyContent='space-between'>
                      {account.deliveryLead}
                      <IconButton
                        aria-label='expand row'
                        size='small'
                        onClick={() =>
                          setOpenAccountId(account.id === openAccountId ? 0 : account.id)
                        }
                      >
                        {openAccountId === account.id ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )}
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
                {/** start collapsable */}
                <TableRow>
                  <TableCell style={{ padding: 0 }} colSpan={5}>
                    <Collapse in={openAccountId === account.id} timeout='auto' unmountOnExit>
                      <Typography variant='h6' ml={'16px'} mt={'16px'} component='div'>
                        Projects
                      </Typography>
                      <ProjectsTable projects={account.projects} />
                    </Collapse>
                  </TableCell>
                </TableRow>
                {/** end collapsable*/}
              </Fragment>
            ))}
          </TableBody>
        </Table>
      </Box>
    </div>
  );
};
