import { Button, FormControl, MenuItem, Select } from '@mui/material';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import styles from './KeyPeopleInPortfolio.module.scss';

interface IAccount {
  name: string;
  id: number;
}

const employees = ['Atila Bala', 'Ivan', 'Slavki', 'Mladja'];

const accounts: IAccount[] = [
  {
    name: 'Apple',
    id: 45,
  },
  {
    name: 'BMW',
    id: 33,
  },
  {
    name: 'McKinsey',
    id: 13,
  },
  {
    name: 'Petrovic',
    id: 55,
  },
];

interface IPeopleInPortfolio {
  id: string;
  employee: string;
  account: number;
  from: string;
  to: string;
}

const peopleInPortfolio: IPeopleInPortfolio[] = [
  {
    id: 'id123',
    employee: 'Slavki',
    account: 33,
    from: '01/01/2013',
    to: '01/01/2013',
  },
  {
    id: 'id959',
    employee: 'Mladja',
    account: 13,
    from: '01/01/2013',
    to: '01/01/2013',
  },
];

type FormValues = {
  data: IPeopleInPortfolio[];
};

const blankItem: IPeopleInPortfolio = {
  id: '',
  employee: '',
  account: 0,
  from: '',
  to: '',
};

export const KeyPeopleInPortfolio = () => {
  const {
    control,
    handleSubmit,
    formState: {
      // isDirty, isValid,
      errors,
    },
  } = useForm<FormValues>({
    defaultValues: {
      data: peopleInPortfolio,
    },
  });

  const { fields, remove, append } = useFieldArray({
    name: 'data',
    control,
  });

  const onSubmit = (data: FormValues) => console.log('@@@ ', data);

  // const handleChangeEmployee = (event: any) => {
  //   console.log(event.target.value);
  // };

  return (
    <div>
      <div className={styles.note}>
        <p>
          <InfoOutlinedIcon fontSize='inherit' /> On this page, you allocate employees that will be
          the overhead of the whole portfolio (choose ---) or the overhead of the specific account
          of the portfolio.
        </p>
        <p>
          You can still allocate those people to a particular project. Keep in mind that the project
          allocation will be deducted from the overhead portfolio/account allocation.
        </p>
        <p>You can allocate the employees to a specific project on their profiles.</p>
      </div>
      <Box mt={'22px'} width='750px'>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Table sx={{ minWidth: 650, marginBottom: '16px' }} aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell>Employee:</TableCell>
                <TableCell>From:</TableCell>
                <TableCell>To:</TableCell>
                <TableCell>Account:</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fields.map((personInPortfolio, index) => (
                <TableRow key={personInPortfolio.id}>
                  <TableCell scope='row'>
                    <FormControl
                      fullWidth
                      size='small'
                      error={errors.data ? !!errors.data[index]?.employee : false}
                    >
                      <Controller
                        name={`data.${index}.employee`}
                        control={control}
                        rules={{
                          validate: (value) => value !== '' || 'greskaaaaa',
                        }}
                        render={({ field }) => (
                          <Select {...field} sx={{ minWidth: '110px' }}>
                            {employees.map((employee) => (
                              <MenuItem value={employee} key={employee}>
                                {employee}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <FormControl fullWidth>
                      <Controller
                        control={control}
                        name={`data.${index}.from`}
                        render={({ field: { onChange, value } }) => (
                          <DatePicker
                            openTo='day'
                            value={dayjs(value)}
                            format='DD-MM-YYYY'
                            onChange={(v) => onChange(dayjs(v).format('DD-MM-YYYY'))}
                            slotProps={{ textField: { size: 'small' } }}
                            slots={{
                              openPickerIcon: KeyboardArrowDownIcon,
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <FormControl fullWidth>
                      <Controller
                        control={control}
                        name={`data.${index}.to`}
                        render={({ field: { onChange, value } }) => (
                          <DatePicker
                            openTo='day'
                            value={dayjs(value)}
                            format='DD-MM-YYYY'
                            onChange={(v) => onChange(dayjs(v).format('DD-MM-YYYY'))}
                            slotProps={{ textField: { size: 'small' } }}
                            slots={{
                              openPickerIcon: KeyboardArrowDownIcon,
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <FormControl fullWidth size='small'>
                      <Controller
                        name={`data.${index}.account`}
                        control={control}
                        render={({ field }) => (
                          <Select {...field} sx={{ minWidth: '110px' }}>
                            {accounts.map((account) => (
                              <MenuItem value={account.id} key={account.id}>
                                {account.name}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </TableCell>
                  <TableCell align='right'>
                    <Button
                      onClick={() => remove(index)}
                      variant='contained'
                      color='error'
                      sx={{ fontSize: '13px' }}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Button
            onClick={() => append(blankItem)}
            variant='contained'
            size='large'
            className={styles.ctaAdd}
            startIcon={<AddIcon />}
          >
            Add Associated Emplyees
          </Button>
          <Button
            type='submit'
            variant='contained'
            size='large'
            // disabled={!isDirty || !isValid}
            sx={{ marginLeft: '16px' }}
            className={styles.ctaAdd}
            startIcon={<SaveIcon />}
          >
            Save Changes
          </Button>
        </form>
      </Box>
    </div>
  );
};
