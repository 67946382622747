import { useState } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Button } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import Chip from '@mui/material/Chip';
import { EditableLabel } from 'components/EditableLabel/EditableLabel';

enum statuses {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  POSTPONED = 'postponed',
}

interface IGeneralAccountInfo {
  name: string;
  portfolio: string;
  accountManager: string;
  industry: string[];
  clientInfo: string;
  website: string;
  status: statuses;
}

type MyEnumValue = `${statuses}`;
const StatusesCollection: MyEnumValue[] = Object.values(statuses);

const accountManagers = ['Zoran Kovacevic', 'Dzenana', 'Bruno'];

const portfolios = ['Portfolio 1', 'Portfolio 2'];

const generalAccountInfo: IGeneralAccountInfo = {
  name: 'Costco Wholesales',
  portfolio: 'Portfolio 1',
  accountManager: 'Zoran Kovacevic',
  industry: ['Agriculture', 'Arts & Entertainment', 'Consulting', 'Education', 'Wellness'],
  clientInfo: `Costco Wholesale Corporation (commonly shortened to Costco) is an
      American multinational corporation which operates a chain of
      membership-only big-box warehouse club retail stores. As of 2023,
      Costco is the third-largest retailer in the world and is the
      world's largest retailer of choice and prime beef, organic
      foods, rotisserie chicken, and wine as of 2016.[5] Costco is
      ranked #11 on the Fortune 500 rankings of the largest United
      States corporations by total revenue.`,
  website: 'https://symphony.is/',
  status: statuses.POSTPONED,
};

export const General = () => {
  const [accountManager, setAccountManager] = useState(generalAccountInfo.accountManager);
  const [portfolio, setPortfolio] = useState(generalAccountInfo.portfolio);
  const [status, setStatus] = useState(generalAccountInfo.status);
  const [industries, setIndustries] = useState<string[]>([]);

  const handleChangeAccountManager = (event: any) => {
    setAccountManager(event.target.value);
  };

  const handleChangePortfolio = (event: any) => {
    setPortfolio(event.target.value);
  };

  const handleChangeStatus = (event: any) => {
    setStatus(event.target.value);
  };

  const handleChange = (event: SelectChangeEvent<typeof industries>) => {
    const {
      target: { value },
    } = event;
    setIndustries(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  return (
    <div>
      <Box mt={'22px'}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={2}>
            <InputLabel
              sx={{
                display: 'flex',
                height: '36px',
                alignItems: 'center',
                fontWeight: 700,
                color: 'black',
              }}
            >
              Name:
            </InputLabel>
          </Grid>
          <Grid item xs={12} sm={10}>
            <EditableLabel value={generalAccountInfo.name} />
          </Grid>
          <Grid item xs={12} sm={2}>
            <InputLabel
              sx={{
                display: 'flex',
                height: '36px',
                alignItems: 'center',
                fontWeight: 700,
                color: 'black',
              }}
            >
              Portfolio:
            </InputLabel>
          </Grid>
          <Grid item xs={12} sm={10}>
            <FormControl size='small'>
              <Select id='demo-simple-select' value={portfolio} onChange={handleChangePortfolio}>
                {portfolios.map((item) => (
                  <MenuItem value={item} key={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={2}>
            <InputLabel
              sx={{
                display: 'flex',
                height: '36px',
                alignItems: 'center',
                fontWeight: 700,
                color: 'black',
              }}
            >
              Account Manager:
            </InputLabel>
          </Grid>
          <Grid item xs={12} sm={10}>
            <FormControl size='small'>
              <Select
                id='demo-simple-select'
                value={accountManager}
                onChange={handleChangeAccountManager}
              >
                {accountManagers.map((manager) => (
                  <MenuItem value={manager} key={manager}>
                    {manager}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={2}>
            <InputLabel
              sx={{
                display: 'flex',
                height: '36px',
                alignItems: 'center',
                fontWeight: 700,
                color: 'black',
              }}
            >
              Industry:
            </InputLabel>
          </Grid>
          <Grid item xs={12} sm={10} sx={{ display: 'flex', alignItems: 'center' }}>
            <FormControl size='small' sx={{ width: 500 }}>
              <Select
                id='multiple-industry'
                multiple
                value={industries}
                onChange={handleChange}
                input={<OutlinedInput id='multiple-industry' sx={{ minHeight: '41px' }} />}
                renderValue={(selected) => (
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: 0.5,
                    }}
                  >
                    {selected.map((value) => (
                      <Chip key={value} label={value} size='small' />
                    ))}
                  </Box>
                )}
              >
                {generalAccountInfo.industry.map((name) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={2}>
            <InputLabel
              sx={{
                display: 'flex',
                fontWeight: 700,
                color: 'black',
              }}
            >
              Client Info:
            </InputLabel>
          </Grid>
          <Grid item xs={12} sm={10} sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box
              sx={{
                border: '1px solid #B3B3BA',
                padding: '8px',
                borderRadius: '4px',
                width: '975px',
              }}
            >
              {generalAccountInfo.clientInfo}
            </Box>
          </Grid>
          <Grid item xs={12} sm={2}>
            <InputLabel
              sx={{
                display: 'flex',
                height: '36px',
                alignItems: 'center',
                fontWeight: 700,
                color: 'black',
              }}
            >
              Website:
            </InputLabel>
          </Grid>
          <Grid item xs={12} sm={10}>
            <EditableLabel value={generalAccountInfo.website} />
          </Grid>
          <Grid item xs={12} sm={2}>
            <InputLabel
              sx={{
                display: 'flex',
                height: '36px',
                alignItems: 'center',
                fontWeight: 700,
                color: 'black',
              }}
            >
              Status:
            </InputLabel>
          </Grid>
          <Grid item xs={12} sm={10}>
            <FormControl size='small'>
              <Select id='demo-simple-select' value={status} onChange={handleChangeStatus}>
                {StatusesCollection.map((status) => (
                  <MenuItem value={status} key={status}>
                    {status}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Button variant='contained'>Account History</Button>
      </Box>
    </div>
  );
};
