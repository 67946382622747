import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useNavigate, useLocation } from 'react-router-dom';
import { APP_ROUTES } from 'routes/paths';
import { googleLogout } from '@react-oauth/google';
import { refreshTokens } from 'pages/login/api';
import {
  RefreshToken,
  getSavedToken,
  getSavedUser,
  removeToken,
  saveToken,
} from 'utils/localStorage';
import axios from 'axios';
import { PERMISSIONS, ROLES, User } from 'utils/user';

const MIN_SECONDS = 120;

export interface IAuthProvider {
  user: User;
  saveUserAndRedirect: (user: User) => void;
  logout: () => void;
}
const emptyUser = {
  username: '',
  picture: '',
  email: '',
  permissions: [],
};

const AuthContext = createContext<IAuthProvider>({} as IAuthProvider);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const redirectPath = location.state?.path || APP_ROUTES.WF_FORECAST;

  const [user, setUser] = useState<User>(() => {
    const userData = getSavedUser();
    if (userData) {
      return adaptUserData(userData);
    }
    return emptyUser;
  });

  const saveUserAndRedirect = useCallback(
    (user: User) => {
      setUser(adaptUserData(user));
      navigate(redirectPath, { replace: true });
    },
    [navigate, redirectPath],
  );

  const logout = useCallback(() => {
    setUser(emptyUser);
    removeToken();
    googleLogout();
  }, []);

  useEffect(() => {
    const tokenStorage = getSavedToken();
    if (!tokenStorage?.expiry_date) return;

    const diff = dayjs(tokenStorage.expiry_date).diff(dayjs(), 'second');
    const num = diff < MIN_SECONDS ? 0 : (diff - MIN_SECONDS) * 1000;

    const timeout = window.setTimeout(() => {
      const tokenStorage = getSavedToken();
      if (!tokenStorage) return;

      const { refresh_token } = tokenStorage;
      if (!refresh_token) {
        logout();
        return;
      }

      refreshTokens<RefreshToken>(refresh_token)
        .then(({ data }) => {
          saveToken({ ...data, refresh_token });
          setUser((u) => ({ ...u }));

          axios.defaults.headers.common['Authorization'] = `Bearer ${data.access_token}`;
          axios.defaults.headers.common['id-token'] = data.id_token;
        })
        .catch(() => logout());
    }, num);

    return () => {
      clearTimeout(timeout);
    };
  }, [logout, user]);

  return (
    <AuthContext.Provider value={{ user, saveUserAndRedirect, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

const adaptUserData = (user: User) => {
  return {
    username: user.username,
    picture: user.picture,
    email: user.email,
    permissions: user.role === ROLES.ADMIN ? [PERMISSIONS.CAN_MODIFY] : [PERMISSIONS.CAN_VIEW],
  };
};
