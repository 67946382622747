import { Box } from '@mui/material';
import { IEmployee, IDeal, IProjectSow } from '__mocks__/wffs';
import styles from './workForceForecast.module.scss';

interface ISowList {
  sows: IProjectSow[];
}

interface IEmployeeList {
  employees: IEmployee[] | null;
}

export const employees = (data: IDeal[] | null) => {
  return data?.map((d) => (
    <div className={styles.employeesTooltip} key={d.id}>
      <Box mb={'6px'}>
        <span>
          Project name: <span className={styles.employeesStatus}>{d.project_name}</span>
        </span>
        <SowsAndDeals sows={d.project_sows} />
      </Box>
    </div>
  ));
};

const SowsAndDeals = ({ sows }: ISowList) => {
  return sows.length ? (
    <div>
      {sows.map((sow) => {
        const probability = sow?.sow_details?.probability
          ? `${sow?.sow_details?.probability}% /`
          : '';
        const fte = sow?.sow_details?.fte ? `FTE: ${sow?.sow_details?.fte} /` : '';
        const type = sow?.sow_details?.type ? `${sow?.sow_details?.fte} /` : '';

        return (
          <Box key={sow.id} ml={'12px'}>
            <Box mt={'4px'}>
              <b>{sow?.sow_details?.name}</b> / {probability} {fte} {type}
              {sow?.sow_details?.start_date} - {sow?.sow_details?.end_date}
            </Box>
            <Box ml={'12px'} mt={'4px'}>
              Engagements: <EmployeeList employees={sow.employees?.engagements || null} />
            </Box>
            <Box ml={'12px'} mt={'4px'}>
              Disengagements: <EmployeeList employees={sow.employees?.disengagements || null} />
            </Box>
          </Box>
        );
      })}
    </div>
  ) : (
    <span>/</span>
  );
};

const EmployeeList = ({ employees }: IEmployeeList) => {
  return employees?.length ? (
    <dl>
      {employees?.map(({ id, name, seniority, job_type, hub, bill_percentage }) => (
        <dt key={id}>
          - {name} / {seniority} / {job_type} / {hub} / {bill_percentage}
        </dt>
      ))}
    </dl>
  ) : (
    <span>/</span>
  );
};
