import { NavLink } from 'react-router-dom';
import { useAuth } from 'provider/AuthProvider';
import { APP_ROUTES } from 'routes/paths';
import UsersSettings from 'pages/usersSettings/UsersSettings';
import styles from './Navbar.module.scss';

export const Navbar = () => {
  const { user } = useAuth();

  return (
    <nav className={styles.navigation}>
      <div className={styles.links}>
        {/* <NavLink to={APP_ROUTES.BASE}>Home</NavLink>
        {user.username && <NavLink to={APP_ROUTES.PORTFOLIOS}>Portfolios</NavLink>} */}
        {user.username && <NavLink to={APP_ROUTES.WF_FORECAST}>WFF</NavLink>}
        {!user.username && <NavLink to={APP_ROUTES.LOGIN}>Login</NavLink>}
      </div>
      {user.username && <UsersSettings />}
    </nav>
  );
};
