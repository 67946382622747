import { createTheme } from '@mui/material/styles';

const backgroundDefault = '#F2F1F3';
const tableHeaderBackgrount = '#222453';

const primaryColor = '#6C69FF';
const primaryColorLight = 'rgb(108, 105, 255)';
const primaryHoverColor = 'rgba(108, 105, 255, 0.1)';

const primaryTextColor = '#0E0E2F';
const secondaryTextColor = '#7F7E8B';

const errorColor = '#FE7475';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: primaryColor,
      light: primaryColorLight,
    },
    background: {
      default: backgroundDefault,
    },
    text: {
      primary: primaryTextColor,
      secondary: secondaryTextColor,
    },
    error: {
      main: errorColor,
      contrastText: '#fff',
    },
    custom: {
      primaryHoverColor,
      headerBackground: '#F8F8F9',
      tableHeaderBackgrount,
    },
  },
  components: {
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: primaryTextColor,
          transition: 'all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;',
          '&:hover': {
            backgroundColor: primaryHoverColor,
            color: primaryColor,
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontSize: '15px',
          fontWeight: 600,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontSize: '14px',
          fontWeight: '600',
          // borderRadius: 100,
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
        },
        text: {
          '&:hover': {
            backgroundColor: primaryHoverColor,
          },
          '&.Mui-disabled': {
            border: '1px solid rgba(0, 0, 0, 0.12)',
          },
        },
        outlined: {
          '&:hover': {
            backgroundColor: primaryHoverColor,
          },
        },
      },
    },

    MuiStepIcon: {
      styleOverrides: {
        root: {
          '&.Mui-completed': {
            color: primaryTextColor,
          },
        },
      },
    },

    MuiStepLabel: {
      styleOverrides: {
        label: {
          '&.Mui-completed': {
            fontWeight: 400,
          },
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            background: backgroundDefault,
          },
        },
      },
    },

    MuiDialog: {
      styleOverrides: {
        paper: {
          minWidth: 470,
          borderRadius: 10,
        },
      },
    },

    MuiDialogTitle: {
      styleOverrides: {
        root: {
          // display: "flex",
          // justifyContent: "center",
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
          ' +.MuiDialogContent-root': {
            paddingTop: '20px !important',
          },
        },
      },
    },

    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '36px',
        },
      },
    },

    MuiDialogActions: {
      styleOverrides: {
        root: {
          borderTop: '1px solid rgba(0, 0, 0, 0.12)',
          padding: '16px 24px',
          justifyContent: 'flex-end',
        },
      },
    },
  },
});

declare module '@mui/material/styles' {
  interface Palette {
    custom: Record<string, string>;
  }

  interface PaletteOptions {
    custom?: Record<string, string>;
  }
}

export default theme;
