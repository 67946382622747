import axios from 'axios';
import { refreshTokens } from 'pages/login/api';
import { config } from './config';
import { RefreshToken, getSavedToken, saveToken } from './localStorage';

axios.defaults.baseURL = config.baseApiUrl;
axios.interceptors.response.use(
  (response) => {
    if (response.status === 401) {
      const tokenStorage = getSavedToken();

      if (tokenStorage?.refresh_token) {
        const { refresh_token } = tokenStorage;

        refreshTokens<RefreshToken>(refresh_token).then(({ data }) => {
          saveToken({ ...data, refresh_token });

          axios.defaults.headers.common['Authorization'] = `Bearer ${data.access_token}`;
          axios.defaults.headers.common['id-token'] = data.id_token;
        });
      }
    }
    return response;
  },
  (error) => Promise.reject(error),
);

const tokenStorage = getSavedToken();

if (tokenStorage) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${tokenStorage.access_token}`;
  axios.defaults.headers.common['id-token'] = tokenStorage.id_token;
}
