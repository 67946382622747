import { IProject } from 'pages/account/associatedProjects/ProjectsTable';

export const projects: IProject[] = [
  {
    id: 1,
    projectName: 'Platoon Managment',
    sdm: 'Zoran Kovacevic',
    fte: 14,
    startDate: '',
    endDate: '',
  },
  {
    id: 2,
    projectName: 'Platoon Analytics',
    sdm: 'Zoran Kovacevic',
    fte: 31,
    startDate: '',
    endDate: '',
  },
  {
    id: 3,
    projectName: 'Platoon Finance',
    sdm: 'Zoran Kovacevic',
    fte: 31,
    startDate: '',
    endDate: '',
  },
  {
    id: 4,
    projectName: 'Hype',
    sdm: 'Zoran Kovacevic',
    fte: 11,
    startDate: '',
    endDate: '',
  },
  {
    id: 5,
    projectName: 'Flow',
    sdm: 'Zoran Kovacevic',
    fte: null,
    startDate: '',
    endDate: '',
  },
  {
    id: 6,
    projectName: 'Scored',
    sdm: 'Macak',
    fte: 150,
    startDate: '',
    endDate: '',
  },
  {
    id: 7,
    projectName: 'Data Tool',
    sdm: 'Irina',
    fte: 44,
    startDate: '',
    endDate: '',
  },
  {
    id: 8,
    projectName: 'C60',
    sdm: 'Jelena',
    fte: 36,
    startDate: '',
    endDate: '',
  },
  {
    id: 9,
    projectName: 'ethiqly',
    sdm: 'Irina',
    fte: 65,
    startDate: '',
    endDate: '',
  },
];
