import TableCell from '@mui/material/TableCell';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import { IWorkforceForecast } from '__mocks__/wffs';

export interface ITableFooterProps {
  total: IWorkforceForecast;
}

export const EnhancedTableFooter = ({ total }: ITableFooterProps) => {
  return (
    <TableFooter
      sx={{
        bottom: 0,
        position: 'sticky',
        backgroundColor: 'white',
        '& td': {
          color: '#0E0E2F',
          fontSize: '11px',
          fontWeight: 600,
          border: 0,
          outline: '1px solid #918f8f',
        },
      }}
    >
      <TableRow>
        <TableCell
          rowSpan={4}
          sx={{
            position: 'sticky',
            left: 0,
            outline: '1px solid #918f8f',
            background: 'white',
          }}
        >
          TOTAL
        </TableCell>
        <TableCell
          rowSpan={4}
          sx={{
            position: 'sticky',
            left: 121,
            outline: '1px solid #918f8f',
            background: 'white',
          }}
        >
          {total.current_total}
        </TableCell>
        <TableCell
          sx={{
            position: 'sticky',
            left: 222,
            outline: '1px solid #918f8f',
            background: 'white',
            fontWeight: 600,
          }}
        >
          Allocated
        </TableCell>
        {total?.data.map((totalItem) => (
          <>
            <TableCell>{totalItem.month.allocated.balance.value}</TableCell>
            <TableCell>{totalItem.month.allocated.total.value}</TableCell>
          </>
        ))}
        <TableCell rowSpan={4}></TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          sx={{
            position: 'sticky',
            left: 222,
            outline: '1px solid #918f8f',
            background: 'white',
          }}
        >
          Softlocked
        </TableCell>
        {total?.data.map((totalItem) => (
          <>
            <TableCell>{totalItem.month.soft_locked.balance.value}</TableCell>
            <TableCell>{totalItem.month.soft_locked.total.value}</TableCell>
          </>
        ))}
      </TableRow>
      <TableRow>
        <TableCell
          sx={{
            position: 'sticky',
            left: 222,
            outline: '1px solid #918f8f',
            background: 'white',
          }}
        >
          Soft lock adjusted
        </TableCell>
        {total?.data.map((totalItem) => (
          <>
            <TableCell>{totalItem.month.soft_locked_adjusted.balance.value}</TableCell>
            <TableCell>{totalItem.month.soft_locked_adjusted.total.value}</TableCell>
          </>
        ))}
      </TableRow>
      <TableRow>
        <TableCell
          sx={{
            position: 'sticky',
            left: 222,
            outline: '1px solid #918f8f',
            background: 'white',
          }}
        >
          Forcasted
        </TableCell>
        {total?.data.map((totalItem) => (
          <>
            <TableCell>{totalItem.month.forecasted.balance.value}</TableCell>
            <TableCell>{totalItem.month.forecasted.total.value}</TableCell>
          </>
        ))}
      </TableRow>
    </TableFooter>
  );
};
