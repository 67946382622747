import Router from 'routes/router';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AuthProvider } from 'provider/AuthProvider';
import { BrowserRouter } from 'react-router-dom';
import { Navbar } from 'pages/navBar/Navbar';
import theme from 'layout/theme';
import 'utils/axios';
import { config } from 'utils/config';

import './App.scss';

function App() {
  const queryClient = new QueryClient();

  return (
    <div className='App'>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ThemeProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <BrowserRouter>
              <AuthProvider>
                <GoogleOAuthProvider clientId={config.googleClientId}>
                  <Navbar />
                  <Router />
                </GoogleOAuthProvider>
              </AuthProvider>
            </BrowserRouter>
          </QueryClientProvider>
        </ThemeProvider>
      </LocalizationProvider>
    </div>
  );
}

export default App;
