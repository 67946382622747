import { useState } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Button } from '@mui/material';
import { EditableLabel } from 'components/EditableLabel/EditableLabel';
import { PortfolioInfo } from '../components/PortfolioInfo';

import styles from './General.module.scss';

interface IEngagement {
  percentage: number;
  name: string;
}

interface IPeopleInPortfolio {
  id: string;
  name: string;
  title: string;
  engagements: IEngagement[];
}

export const General = () => {
  const [age, setAge] = useState('');

  const handleChange = (event: any) => {
    setAge(event.target.value);
  };

  const directors = ['Atila Bala', 'Ivan', 'Slavki', 'Mladja'];
  const industries = ['Agriculture', 'Arts & Entertainment', 'Consulting', 'Education', 'Wellness'];
  const peopleInPortfolio: IPeopleInPortfolio[] = [
    {
      id: 'qw23',
      name: 'Zoran Kovacevic',
      title: 'Account Director',
      engagements: [{ percentage: 100, name: 'Overhead of Apple' }],
    },
    {
      id: 'po312',
      name: 'Dzenana Laletovic',
      title: 'Solution Architect',
      engagements: [{ percentage: 100, name: 'Portfolio iOverhead' }],
    },
    {
      id: 'nkl979',
      name: 'Bruno Omerovic',
      title: 'Product Strategist',
      engagements: [
        { percentage: 50, name: 'Hitachi Overhead' },
        { percentage: 50, name: 'Vantage Allocation' },
      ],
    },
  ];
  return (
    <div>
      <PortfolioInfo
        name={'Portfolio 1'}
        numberOfAccounts={10}
        numberOfProjects={19}
        fte={120}
        keyPeopleInPortfolio={3}
      />
      <Box mt={'22px'}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={2}>
            <InputLabel
              sx={{
                display: 'flex',
                height: '36px',
                alignItems: 'center',
                fontWeight: 700,
                color: 'black',
              }}
            >
              Name:
            </InputLabel>
          </Grid>
          <Grid item xs={12} sm={10}>
            <EditableLabel value={'Portfolio 1'} />
          </Grid>
          <Grid item xs={12} sm={2}>
            <InputLabel
              sx={{
                display: 'flex',
                height: '36px',
                alignItems: 'center',
                fontWeight: 700,
                color: 'black',
              }}
            >
              Portfolio director:
            </InputLabel>
          </Grid>
          <Grid item xs={12} sm={10}>
            <FormControl size='small'>
              <Select id='demo-simple-select' value={age} onChange={handleChange}>
                {directors.map((item) => (
                  <MenuItem value={item} key={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={2}>
            <InputLabel
              sx={{
                display: 'flex',
                height: '36px',
                alignItems: 'center',
                fontWeight: 700,
                color: 'black',
              }}
            >
              Industries:
            </InputLabel>
          </Grid>
          <Grid item xs={12} sm={10} sx={{ display: 'flex' }}>
            {industries.map((industry) => (
              <span className={styles.chip} key={industry}>
                {industry}
              </span>
            ))}
          </Grid>
          <Grid item xs={12} sm={2}>
            <InputLabel
              sx={{
                display: 'flex',
                fontWeight: 700,
                color: 'black',
              }}
            >
              Key People in Portfolio:
            </InputLabel>
          </Grid>
          <Grid item xs={12} sm={10} sx={{ display: 'flex', flexDirection: 'column' }}>
            {peopleInPortfolio.map((person) => (
              <div style={{ lineHeight: '20px', marginBottom: '12px' }} key={person.id}>
                <b>
                  {person.name}({person.title})
                </b>
                <span>
                  {person.engagements.map(
                    (engagement) => ` / ${engagement.percentage}% ${engagement.name} `,
                  )}
                </span>
              </div>
            ))}
          </Grid>
        </Grid>
        <Button variant='contained'>View History of Associated Employees</Button>
      </Box>
    </div>
  );
};
