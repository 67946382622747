export enum ROLES {
  ADMIN = 'ADMIN',
}

export enum PERMISSIONS {
  CAN_VIEW = 'VIEW',
  CAN_MODIFY = 'MODIFY',
}

export type User = {
  username: string;
  picture: string;
  email: string;
  permissions?: PERMISSIONS[];
  role?: ROLES;
};
