import { useGoogleLogin, UseGoogleLoginOptionsAuthCodeFlow } from '@react-oauth/google';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useAuth } from 'provider/AuthProvider';
import { Button } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import { RawToken, saveToken, saveUser } from 'utils/localStorage';
import axios from 'axios';
import { adaptUserDataFromToken } from 'utils/extractUserData';
import { getTokens } from './api';

type onSuccess = UseGoogleLoginOptionsAuthCodeFlow['onSuccess'];
type onError = UseGoogleLoginOptionsAuthCodeFlow['onError'];

const Login = () => {
  const { saveUserAndRedirect } = useAuth();
  const onSuccess: onSuccess = ({ code }) => {
    getTokens<RawToken>(code).then(({ data }) => {
      const userData = adaptUserDataFromToken(data.id_token);

      saveToken(data);
      saveUser(userData);

      saveUserAndRedirect(userData);

      axios.defaults.headers.common['Authorization'] = `Bearer ${data.access_token}`;
      axios.defaults.headers.common['id-token'] = data.id_token;
    });
  };
  const onError: onError = (error) => console.log('Login Failed:', error);

  const googleLogin = useGoogleLogin({
    hosted_domain: 'symphony.is',
    flow: 'auth-code',
    onSuccess,
    onError,
  });

  const googleLoginHandler = () => googleLogin();

  return (
    <Container component='main' maxWidth='xs'>
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
          <LockOutlinedIcon fontSize='large' />
        </Avatar>
        <Box mt={'12px'}>
          <Button
            variant='contained'
            size='large'
            onClick={googleLoginHandler}
            startIcon={<GoogleIcon />}
          >
            <Typography fontSize={18}>Sign in with Google</Typography>
          </Button>
        </Box>
      </Box>
    </Container>
  );
};
export default Login;
