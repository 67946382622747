import styles from './PortfolioInfo.module.scss';

interface IPortfolioInfo {
  name: string;
  numberOfAccounts: number;
  numberOfProjects: number;
  fte: number;
  keyPeopleInPortfolio: number;
}

export const PortfolioInfo = ({
  name,
  numberOfAccounts,
  numberOfProjects,
  fte,
  keyPeopleInPortfolio,
}: IPortfolioInfo) => {
  return (
    <div className={styles.content}>
      <div>
        Portfolio Name: <span className={styles.value}>{name}</span>
      </div>
      <div>
        Number of Accounts: <span className={styles.value}>{numberOfAccounts}</span>
      </div>
      <div>
        Number of Projects: <span className={styles.value}>{numberOfProjects}</span>
      </div>
      <div>
        Portfolio FTE's: <span className={styles.value}>{fte}</span>
      </div>
      <div>
        Key People in Portfolio: <span className={styles.value}>{keyPeopleInPortfolio}</span>
      </div>
    </div>
  );
};
