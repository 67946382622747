import { Routes, Route } from 'react-router-dom';
import { Portfolios } from 'pages/portfolios/Portfolios';
import { Portfolio } from 'pages/portfolio/Portfolio';
import { Account } from 'pages/account/Account';
import Login from 'pages/login/Login';
import { WorkForceForecast } from 'pages/workForceForecast/WorkForceForecast';
import { PERMISSIONS } from 'utils/user';
import { APP_ROUTES } from './paths';
import Authorization from './Authorization';

export const Router = () => {
  return (
    <Routes>
      <Route path={`${APP_ROUTES.LOGIN}`} element={<Login />} />
      <Route element={<Authorization permissions={[PERMISSIONS.CAN_MODIFY]} />}>
        <Route path={`${APP_ROUTES.PORTFOLIOS}`} element={<Portfolios />}></Route>
        <Route path={`${APP_ROUTES.PORTFOLIO}`} element={<Portfolio />}></Route>
        <Route path={`${APP_ROUTES.ACCOUNT}`} element={<Account />}></Route>
        <Route path={`${APP_ROUTES.WF_FORECAST}`} element={<WorkForceForecast />}></Route>
      </Route>
    </Routes>
  );
};
export default Router;
