import { useState } from 'react';
import { Box, IconButton, TextField, Tooltip } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import EditOffOutlinedIcon from '@mui/icons-material/EditOffOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';

export const EditableLabel = ({ value }: any) => {
  const [isEditMode, setEditMode] = useState(false);

  const handleClickEdit = () => {
    setEditMode(true);
  };

  const disableEditMode = () => {
    setEditMode(false);
  };

  const saveChanges = () => {
    setEditMode(false);
  };

  return (
    <Box display='flex' alignItems='center'>
      <TextField
        id='name'
        name='name'
        size='small'
        autoComplete='off'
        value={value}
        sx={{ width: '170px' }}
        disabled={!isEditMode}
      />
      {isEditMode ? (
        <>
          <Tooltip title='Cancel'>
            <IconButton
              aria-label='cancel'
              size='small'
              sx={{ marginLeft: '9px' }}
              onClick={disableEditMode}
            >
              <EditOffOutlinedIcon fontSize='large' color='disabled' />
            </IconButton>
          </Tooltip>
          <Tooltip title='Save'>
            <IconButton
              aria-label='save'
              size='small'
              sx={{ marginLeft: '9px' }}
              onClick={saveChanges}
            >
              <CheckOutlinedIcon fontSize='large' color='disabled' />
            </IconButton>
          </Tooltip>
        </>
      ) : (
        <Tooltip title='Edit'>
          <IconButton
            aria-label='edit'
            size='small'
            sx={{ marginLeft: '9px' }}
            onClick={handleClickEdit}
          >
            <EditOutlinedIcon fontSize='large' color='disabled' />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
};
