import { useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { APP_ROUTES } from 'routes/paths';
import { createRoute } from 'routes/utils';

export interface IProject {
  projectName: string;
  fte: number | null;
  startDate: string;
  endDate: string;
  sdm: string;
  id: number;
}

interface IProjects {
  projects: IProject[];
}

export const ProjectsTable = ({ projects }: IProjects) => {
  const navigate = useNavigate();

  const goToPortfolio = (id: number) => navigate(createRoute(APP_ROUTES.PORTFOLIO, { id }));

  return (
    <Table sx={{ minWidth: 650, tableLayout: 'fixed' }} aria-label='simple table'>
      <TableHead>
        <TableRow>
          <TableCell>Project</TableCell>
          <TableCell>FTE's</TableCell>
          <TableCell>Start Date</TableCell>
          <TableCell>End Date</TableCell>
          <TableCell>SDM</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {projects.map((row) => (
          <TableRow key={row.projectName}>
            <TableCell component='th' scope='row'>
              <a aria-hidden='true' onClick={() => goToPortfolio(row.id)}>
                {row.projectName}
              </a>
            </TableCell>
            <TableCell>{row.fte}</TableCell>
            <TableCell>{row.startDate}</TableCell>
            <TableCell>{row.endDate}</TableCell>
            <TableCell>{row.sdm}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
