import { useMemo, useState } from 'react';
import {
  Box,
  ClickAwayListener,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
  Typography,
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import LinearProgress from '@mui/material/LinearProgress';
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { IWorkforceForecastFull, IList, IWorkforceForecast } from '__mocks__/wffs';
import { calculateBackgroundColor, getComparator, primitiveDataComparator } from './utils';
import { EnhancedTableHead } from './EnhancedTableHead';
import { EnhancedTableFooter } from './EnhancedTableFooter';
import { employees } from './Employees';
import styles from './workForceForecast.module.scss';

export enum LOCKED {
  SOFT = 'soft',
  HARD = 'hard',
}

export enum STATISTICS_TYPE {
  BALANCE = 'balance',
  TOTAL = 'total',
}

export type Order = 'asc' | 'desc';

export interface IOrderBy {
  index: number;
  column: STATISTICS_TYPE | keyof IWorkforceForecast;
}

const initialOrderState: IOrderBy = {
  index: 0,
  column: STATISTICS_TYPE.BALANCE,
};

export const WorkForceForecast = () => {
  const [year, setYear] = useState<Date | null | Dayjs>(new Date());
  const [openTooltip, setOpenTooltipValue] = useState<string | null>(null);
  const [selectedPortfolios, setSelectedPortfolios] = useState<IList[]>([]);
  const [selectedAccounts, setSelectedAccount] = useState<IList[]>([]);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<IOrderBy>(initialOrderState);

  const toggleClick = (id: string | null) => {
    setOpenTooltipValue(openTooltip === id ? null : id);
  };

  const {
    data: wffResponse,
    isLoading,
    isError,
  } = useQuery({
    refetchOnWindowFocus: false,
    queryKey: ['wffs', year, selectedPortfolios, selectedAccounts],
    queryFn: () =>
      axios.get<IWorkforceForecastFull>('/api/accounts-workforce-forecast', {
        params: {
          year: dayjs(year).get('year'),
          accountIds: selectedAccounts.map((selectedAccount) => selectedAccount.id),
          portfolioIds: selectedPortfolios.map((portfolio) => portfolio.id),
        },
        paramsSerializer: {
          indexes: null,
        },
      }),
  });

  const { data: portfolios } = useQuery({
    refetchOnWindowFocus: false,
    queryKey: ['portfolios', year],
    queryFn: () =>
      axios.get<IList[]>('/api/accounts-workforce-forecast-portfolio-choices', {
        params: {
          year: dayjs(year).get('year'),
        },
      }),
  });

  const { data: accountsReponse } = useQuery({
    refetchOnWindowFocus: false,
    queryKey: ['accounts', year, selectedPortfolios],
    queryFn: () =>
      axios.get<IList[]>('/api/accounts-workforce-forecast-account-choices', {
        params: {
          year: dayjs(year).get('year'),
          portfolioIds: selectedPortfolios.map((portfolio) => portfolio.id),
        },
        paramsSerializer: {
          indexes: null,
        },
      }),
  });

  const handleYearChange = (newDate: Dayjs | null) => {
    setYear(newDate);
    setSelectedPortfolios([]);
    setSelectedAccount([]);
  };

  const handlePortfolioChange = (event: SelectChangeEvent<any>) => {
    const {
      target: { value },
    } = event;
    const selected = value.map((v: number) => portfolios?.data.find((option) => option.id === v));
    setSelectedPortfolios(selected);
    setSelectedAccount([]);
  };

  const handleAccountChange = (event: SelectChangeEvent<any>) => {
    const {
      target: { value },
    } = event;
    const selected = value.map((v: number) =>
      accountsReponse?.data.find((option) => option.id === v),
    );
    setSelectedAccount(selected);
  };

  const visibleRows = useMemo(() => {
    /***
     * index -1 represents all values that are out of months collection (e.g. account_name, current_total)
     */
    return orderBy.index === -1
      ? wffResponse?.data?.data?.sort(primitiveDataComparator(order, orderBy))
      : wffResponse?.data?.data?.sort(getComparator(order, orderBy));
  }, [order, orderBy, wffResponse]);

  return (
    <>
      <Box display={'flex'} alignItems={'center'} gap={2}>
        <DatePicker
          label='Year'
          sx={{ width: 200 }}
          views={['year']}
          slotProps={{ textField: { size: 'small' }, field: { readOnly: true } }}
          value={dayjs(year)}
          onChange={handleYearChange}
        />
        <FormControl size='small'>
          <InputLabel id='select-portfolios'>Portfolios</InputLabel>
          <Select
            label='Portfolios'
            labelId='select-portfolios'
            sx={{ width: 200 }}
            multiple
            disabled={dayjs(year).get('year') < 2024}
            value={selectedPortfolios.map((portfolio) => portfolio?.id)}
            onChange={handlePortfolioChange}
            renderValue={(selected) =>
              selected.map(
                (portfolio, index) =>
                  `${index ? ', ' : ''}${
                    portfolios?.data.find((_portfolio) => _portfolio.id === portfolio)!.name
                  }`,
              )
            }
          >
            {portfolios?.data?.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option?.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl size='small'>
          <InputLabel id='select-accounts'>Accounts</InputLabel>
          <Select
            label='Accounts'
            labelId='select-accounts'
            sx={{ width: 200 }}
            multiple
            value={selectedAccounts.map((account) => account?.id)}
            onChange={handleAccountChange}
            renderValue={(selected) =>
              selected.map(
                (account, index) =>
                  `${index ? ', ' : ''}${
                    accountsReponse?.data.find((_account) => _account.id === account)!.name
                  }`,
              )
            }
          >
            {accountsReponse?.data?.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option?.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {isError && (
          <Box display={'flex'} alignItems={'center'} gap={'6px'}>
            <ErrorOutlineIcon color='error' />
            <Typography color='error'>Something went wrong while connecting to the API</Typography>
          </Box>
        )}
      </Box>
      <Box height={'4px'} bgcolor={'#6C69FF'} mt={'20px'}>
        {isLoading && <LinearProgress />}
      </Box>
      <Box className={styles.tableWrapper}>
        <Table
          sx={{
            minWidth: 650,
            '& .MuiTableCell-root': {
              borderRight: '1px solid #e0e0e0',
              textAlign: 'center',
              padding: 0,
            },
          }}
          size='small'
          aria-label='forecast table'
        >
          <EnhancedTableHead
            order={order}
            setOrderClbck={setOrder}
            orderBy={orderBy}
            setOrderByClbck={setOrderBy}
          />
          {wffResponse?.data?.data === null ? (
            <TableBody>
              <TableRow>
                <TableCell colSpan={27}>no records found</TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {visibleRows?.map((row) => (
                <>
                  <TableRow>
                    <TableCell
                      rowSpan={2}
                      sx={{
                        position: 'sticky',
                        left: 0,
                        outline: '1px solid #918f8f',
                        background: 'white',
                        fontWeight: 600,
                      }}
                    >
                      {row.account_name}
                    </TableCell>
                    <TableCell
                      sx={{
                        position: 'sticky',
                        background: 'white',
                        outline: '1px solid #918f8f',
                        left: 121,
                        borderBottom: '2px solid #3e3c3c',
                      }}
                      rowSpan={4}
                    >
                      {row.current_total}
                    </TableCell>
                    <TableCell
                      sx={{
                        position: 'sticky',
                        left: 222,
                        outline: '1px solid #918f8f',
                        background: 'white',
                        fontWeight: 600,
                      }}
                    >
                      Allocated
                    </TableCell>
                    {row.data.map((value) => (
                      <>
                        <TableCell
                          key={value.month.allocated.balance.id}
                          sx={{
                            '&.MuiTableCell-root:hover': {
                              cursor: value.month.allocated.balance.value?.toString()
                                ? 'pointer'
                                : 'initial',
                            },
                          }}
                          style={{
                            fontWeight: '600',
                            backgroundColor: calculateBackgroundColor(
                              value.month.allocated.balance.value,
                              false,
                              openTooltip === value.month.allocated.balance.id,
                              false,
                            ),
                          }}
                        >
                          <ClickAwayListener
                            mouseEvent='onMouseDown'
                            touchEvent='onTouchStart'
                            onClickAway={() => setOpenTooltipValue(null)}
                          >
                            <Tooltip
                              onClose={() => toggleClick(null)}
                              open={openTooltip === value.month.allocated.balance.id}
                              arrow
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              componentsProps={{
                                tooltip: {
                                  sx: {
                                    '&.MuiTooltip-tooltip': {
                                      maxWidth: '500px',
                                      maxHeight: '600px',
                                      overflow: 'auto',
                                    },
                                  },
                                },
                              }}
                              title={employees(value.month.allocated.data)}
                              placement='right'
                            >
                              <div
                                className='withTooltip'
                                onClick={() => toggleClick(value.month.allocated.balance.id)}
                                aria-hidden
                              >
                                {value.month.allocated.balance.value}
                              </div>
                            </Tooltip>
                          </ClickAwayListener>
                        </TableCell>
                        <TableCell
                          key={value.month.allocated.total.id}
                          style={{
                            fontWeight: '600',
                            borderRight: '2px solid #3e3c3c',
                            backgroundColor: calculateBackgroundColor(
                              value.month.allocated.total.value,
                              value.month.is_ending,
                              false,
                              true,
                            ),
                          }}
                        >
                          {value.month.allocated.total.value}
                        </TableCell>
                      </>
                    ))}
                    <TableCell rowSpan={4} />
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        position: 'sticky',
                        left: 222,
                        outline: '1px solid #918f8f',
                        background: 'white',
                      }}
                    >
                      Softlocked
                    </TableCell>
                    {row.data.map((value) => (
                      <>
                        <TableCell
                          sx={{
                            '&.MuiTableCell-root:hover': {
                              cursor: value.month.soft_locked.balance.value?.toString()
                                ? 'pointer'
                                : 'initial',
                            },
                          }}
                          key={value.month.soft_locked.balance.id}
                          style={{
                            backgroundColor: calculateBackgroundColor(
                              value.month.soft_locked.balance.value,
                              false,
                              openTooltip === value.month.soft_locked.balance.id,
                              false,
                            ),
                          }}
                        >
                          <ClickAwayListener
                            mouseEvent='onMouseDown'
                            touchEvent='onTouchStart'
                            onClickAway={() => setOpenTooltipValue(null)}
                          >
                            <Tooltip
                              onClose={() => toggleClick(null)}
                              open={openTooltip === value.month.soft_locked.balance.id}
                              arrow
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              componentsProps={{
                                tooltip: {
                                  sx: {
                                    '&.MuiTooltip-tooltip': {
                                      maxWidth: '500px',
                                      maxHeight: '600px',
                                      overflow: 'auto',
                                    },
                                  },
                                },
                              }}
                              title={employees(value.month.soft_locked.data)}
                              placement='right'
                            >
                              <div
                                className='withTooltip'
                                onClick={() => toggleClick(value.month.soft_locked.balance.id)}
                                aria-hidden
                              >
                                {value.month.soft_locked.balance.value}
                              </div>
                            </Tooltip>
                          </ClickAwayListener>
                        </TableCell>
                        <TableCell
                          key={value.month.soft_locked.total.id}
                          style={{
                            borderRight: '2px solid #3e3c3c',
                            backgroundColor: calculateBackgroundColor(
                              value.month.soft_locked.total.value,
                              value.month.is_ending,
                              false,
                              true,
                            ),
                          }}
                        >
                          {value.month.soft_locked.total.value}
                        </TableCell>
                      </>
                    ))}
                  </TableRow>
                  <TableRow>
                    <TableCell
                      rowSpan={2}
                      sx={{
                        position: 'sticky',
                        left: 0,
                        outline: '1px solid #918f8f',
                        background: 'white',
                      }}
                    >
                      {row.portfolio_name || '/'}
                    </TableCell>
                    <TableCell
                      sx={{
                        position: 'sticky',
                        left: 222,
                        outline: '1px solid #918f8f',
                        background: 'white',
                      }}
                    >
                      Soft lock adjusted
                    </TableCell>
                    {row.data.map((value) => (
                      <>
                        <TableCell
                          sx={{
                            '&.MuiTableCell-root:hover': {
                              cursor: value.month.soft_locked_adjusted.balance.value?.toString()
                                ? 'pointer'
                                : 'initial',
                            },
                          }}
                          key={value.month.soft_locked_adjusted.balance.id}
                          style={{
                            backgroundColor: calculateBackgroundColor(
                              value.month.soft_locked_adjusted.balance.value,
                              false,
                              openTooltip === value.month.soft_locked_adjusted.balance.id,
                              false,
                            ),
                          }}
                        >
                          <ClickAwayListener
                            mouseEvent='onMouseDown'
                            touchEvent='onTouchStart'
                            onClickAway={() => setOpenTooltipValue(null)}
                          >
                            <Tooltip
                              onClose={() => toggleClick(null)}
                              open={openTooltip === value.month.soft_locked_adjusted.balance.id}
                              arrow
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              componentsProps={{
                                tooltip: {
                                  sx: {
                                    '&.MuiTooltip-tooltip': {
                                      maxWidth: '500px',
                                      maxHeight: '600px',
                                      overflow: 'auto',
                                    },
                                  },
                                },
                              }}
                              title={employees(value.month.soft_locked_adjusted.data)}
                              placement='right'
                            >
                              <div
                                className='withTooltip'
                                onClick={() =>
                                  toggleClick(value.month.soft_locked_adjusted.balance.id)
                                }
                                aria-hidden
                              >
                                {value.month.soft_locked_adjusted.balance.value}
                              </div>
                            </Tooltip>
                          </ClickAwayListener>
                        </TableCell>
                        <TableCell
                          key={value.month.soft_locked_adjusted.total.id}
                          style={{
                            borderRight: '2px solid #3e3c3c',
                            backgroundColor: calculateBackgroundColor(
                              value.month.soft_locked_adjusted.total.value,
                              value.month.is_ending,
                              false,
                              true,
                            ),
                          }}
                        >
                          {value.month.soft_locked_adjusted.total.value}
                        </TableCell>
                      </>
                    ))}
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        position: 'sticky',
                        left: 222,
                        outline: '1px solid #918f8f',
                        background: 'white',
                      }}
                    >
                      Forecasted
                    </TableCell>
                    {row.data.map((value) => (
                      <>
                        <TableCell
                          sx={{
                            '&.MuiTableCell-root:hover': {
                              cursor: value.month.forecasted.balance.value?.toString()
                                ? 'pointer'
                                : 'initial',
                            },
                          }}
                          key={value.month.forecasted.balance.id}
                          style={{
                            borderBottom: '2px solid #3e3c3c',
                            backgroundColor: calculateBackgroundColor(
                              value.month.forecasted.balance.value,
                              false,
                              openTooltip === value.month.forecasted.balance.id,
                              false,
                            ),
                          }}
                        >
                          <ClickAwayListener
                            mouseEvent='onMouseDown'
                            touchEvent='onTouchStart'
                            onClickAway={() => setOpenTooltipValue(null)}
                          >
                            <Tooltip
                              onClose={() => toggleClick(null)}
                              open={openTooltip === value.month.forecasted.balance.id}
                              arrow
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              componentsProps={{
                                tooltip: {
                                  sx: {
                                    '&.MuiTooltip-tooltip': {
                                      maxWidth: '500px',
                                      maxHeight: '600px',
                                      overflow: 'auto',
                                    },
                                  },
                                },
                              }}
                              title={employees(value?.month?.forecasted?.data)}
                              placement='right'
                            >
                              <div
                                className='withTooltip'
                                onClick={() => toggleClick(value.month.forecasted.balance.id)}
                                aria-hidden
                              >
                                {value.month.forecasted.balance.value}
                              </div>
                            </Tooltip>
                          </ClickAwayListener>
                        </TableCell>
                        <TableCell
                          key={value.month.forecasted.total.id}
                          style={{
                            borderBottom: '2px solid #3e3c3c',
                            borderRight: '2px solid #3e3c3c',
                            backgroundColor: calculateBackgroundColor(
                              value.month.forecasted.total.value,
                              value.month.is_ending,
                              false,
                              true,
                            ),
                          }}
                        >
                          {value.month.forecasted.total.value}
                        </TableCell>
                      </>
                    ))}
                  </TableRow>
                </>
              ))}
            </TableBody>
          )}
          {wffResponse?.data?.total && <EnhancedTableFooter total={wffResponse?.data?.total} />}
        </Table>
      </Box>
    </>
  );
};
