import { useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, Breadcrumbs } from '@mui/material';
import Link from '@mui/material/Link';
import { Typography } from '@mui/material';
import { Button } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { APP_ROUTES } from 'routes/paths';
import { createRoute } from 'routes/utils';

function createData(
  name: string,
  director: string,
  numberOfAccounts: number,
  numberOfProjects: number,
  accountFTE: number,
  keyPeopleInPortfolio: number,
  id: number,
) {
  return {
    name,
    director,
    numberOfAccounts,
    numberOfProjects,
    accountFTE,
    keyPeopleInPortfolio,
    id,
  };
}

const rows = [
  createData('Frozen yoghurt', 'Atila Bala', 6, 24, 120, 5, 1),
  createData('Ice cream sandwich', 'Dino Osmanovic', 9, 37, 103, 6, 2),
  createData('Eclair', 'Demir Selmanovic', 16, 24, 106, 7, 3),
  createData('Cupcake', 'Irfan Godinjak', 7, 67, 110, 4, 4),
  createData('Gingerbread', 'Jaroen Rook', 16, 49, 119, 5, 5),
];

export const Portfolios = () => {
  const navigate = useNavigate();

  const goToPortfolio = (id: number) => navigate(createRoute(APP_ROUTES.PORTFOLIO, { id }));

  return (
    <>
      <Breadcrumbs aria-label='breadcrumb' separator={<NavigateNextIcon fontSize='small' />}>
        <Link
          underline='hover'
          color='primary'
          style={{ cursor: 'pointer' }}
          onClick={() => {
            navigate(APP_ROUTES.BASE);
          }}
        >
          Home
        </Link>
        <Typography>Business</Typography>
        <Typography color='text.primary'>Portfolios</Typography>
      </Breadcrumbs>
      <Box display='flex' justifyContent='space-between' height='32px' mt='33px' mb='33px'>
        <Typography variant='h6' gutterBottom sx={{ paddingBottom: 5 }}>
          Portfolios
        </Typography>
        <Button variant='contained' size='large'>
          Create Portfolio
        </Button>
      </Box>
      <Table sx={{ minWidth: 650 }} aria-label='simple table'>
        <TableHead>
          <TableRow>
            <TableCell>Dessert (100g serving)</TableCell>
            <TableCell>Director</TableCell>
            <TableCell>Number of accounts</TableCell>
            <TableCell>Number of projects</TableCell>
            <TableCell>Account FTE's</TableCell>
            <TableCell>Key People in Portfolio</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name}>
              <TableCell component='th' scope='row'>
                <a aria-hidden='true' onClick={() => goToPortfolio(row.id)}>
                  {row.name}
                </a>
              </TableCell>
              <TableCell>{row.director}</TableCell>
              <TableCell>{row.numberOfAccounts}</TableCell>
              <TableCell>{row.numberOfProjects}</TableCell>
              <TableCell>{row.accountFTE}</TableCell>
              <TableCell>{row.keyPeopleInPortfolio}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};
