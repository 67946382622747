import { jwtDecode, JwtPayload } from 'jwt-decode';
import { ROLES } from './user';

interface JWTParsed extends JwtPayload {
  name: string;
  picture: string;
  email: string;
}

export const extractUserData = (idToken: string) => jwtDecode<JWTParsed>(idToken);

export const adaptUserDataFromToken = (idToken: string) => {
  const { name, picture, email } = extractUserData(idToken);

  return {
    role: ROLES.ADMIN,
    username: name,
    picture,
    email,
  }; // NOTE: for now, all are admins
};
