import { TableSortLabel } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { IWorkforceForecast } from '__mocks__/wffs';
import styles from './workForceForecast.module.scss';
import { IOrderBy, Order, STATISTICS_TYPE } from './WorkForceForecast';

export interface ITableHeaderProps {
  order: Order;
  setOrderClbck: (order: Order) => void;
  orderBy: IOrderBy;
  setOrderByClbck: (o: IOrderBy) => void;
}

const MONTHS: string[] = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'June',
  'July',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const EnhancedTableHead = ({
  order,
  setOrderClbck,
  orderBy,
  setOrderByClbck,
}: ITableHeaderProps) => {
  const handleRequestSort = (index: number, type: STATISTICS_TYPE) => {
    const isAsc = orderBy.index === index && order === 'asc';
    setOrderClbck(isAsc ? 'desc' : 'asc');
    setOrderByClbck({ index, column: type });
  };

  const createSortHandler = (index: number, type: STATISTICS_TYPE) => () => {
    handleRequestSort(index, type);
  };

  const primitiveDataSortHandler = (attribute: keyof IWorkforceForecast) => {
    const isAsc = orderBy.column === attribute && order === 'asc';
    setOrderClbck(isAsc ? 'desc' : 'asc');
    setOrderByClbck({ index: -1, column: attribute });
  };

  return (
    <TableHead
      className={styles.th}
      sx={{
        top: 0,
        position: 'sticky',
        zIndex: 2,
        backgroundColor: 'white',
        '& th': {
          border: 0,
          outline: '1px solid #918f8f',
        },
      }}
    >
      <TableRow>
        <TableCell
          rowSpan={2}
          sx={{
            minWidth: '120px',
            position: 'sticky',
            left: 0,
            outline: '1px solid #918f8f',
            background: 'white',
            zIndex: 1,
          }}
        >
          <TableSortLabel
            active={orderBy.column === 'account_name'}
            direction={orderBy.column === 'account_name' ? order : 'asc'}
            onClick={() => primitiveDataSortHandler('account_name')}
            sx={{
              '& .MuiTableSortLabel-icon': {
                margin: '0 !important',
                height: 15,
              },
            }}
          >
            Account
          </TableSortLabel>
        </TableCell>
        <TableCell
          rowSpan={2}
          sx={{
            minWidth: '100px',
            position: 'sticky',
            left: 121,
            outline: '1px solid #918f8f',
            background: 'white',
            zIndex: 1,
          }}
        >
          <TableSortLabel
            active={orderBy.column === 'current_total'}
            direction={orderBy.column === 'current_total' ? order : 'asc'}
            onClick={() => primitiveDataSortHandler('current_total')}
            sx={{
              lineHeight: 'normal',
              '& .MuiTableSortLabel-icon': {
                margin: '0 !important',
                height: 15,
              },
            }}
          >
            Total current headcount
          </TableSortLabel>
        </TableCell>
        <TableCell
          rowSpan={2}
          sx={{
            minWidth: '100px',
            position: 'sticky',
            left: 222,
            outline: '1px solid #918f8f',
            background: 'white',
            zIndex: 1,
          }}
        >
          Allocation
        </TableCell>
        {MONTHS.map((month) => (
          <TableCell key={month} colSpan={2}>
            {month}
          </TableCell>
        ))}
        <TableCell rowSpan={2} sx={{ minWidth: 1168 }}></TableCell>
      </TableRow>
      <TableRow>
        {Array(12)
          .fill(null)
          .map((_, index) => {
            const isEqualIndex = orderBy.index === index;
            return (
              <>
                <TableCell>
                  <TableSortLabel
                    active={isEqualIndex && orderBy.column === STATISTICS_TYPE.BALANCE}
                    direction={
                      isEqualIndex && orderBy.column === STATISTICS_TYPE.BALANCE ? order : 'asc'
                    }
                    onClick={createSortHandler(index, STATISTICS_TYPE.BALANCE)}
                    sx={{
                      '& .MuiTableSortLabel-icon': {
                        margin: '0 !important',
                        height: 15,
                      },
                    }}
                  >
                    Balance
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={isEqualIndex && orderBy.column === STATISTICS_TYPE.TOTAL}
                    direction={
                      isEqualIndex && orderBy.column === STATISTICS_TYPE.TOTAL ? order : 'asc'
                    }
                    onClick={createSortHandler(index, STATISTICS_TYPE.TOTAL)}
                    sx={{
                      '& .MuiTableSortLabel-icon': {
                        margin: '0 !important',
                        height: 15,
                      },
                    }}
                  >
                    Total
                  </TableSortLabel>
                </TableCell>
              </>
            );
          })}
      </TableRow>
    </TableHead>
  );
};
