import dayjs from 'dayjs';
import { User } from './user';

enum localStorageKeys {
  token = 'very-cool-and-special-token',
  user = 'kind-and-cool-user',
}

const getLocalStorage = (key: localStorageKeys) => {
  const item = localStorage.getItem(key);

  try {
    if (item) {
      return JSON.parse(item);
    }
  } catch {
    console.error('Error while reading local storage');
  }
};
const setLocalStorage = (key: localStorageKeys, value: unknown) => {
  const v = JSON.stringify(value);
  localStorage.setItem(key, v);
};

export type RawToken = {
  access_token: string;
  refresh_token: string;
  scope: string;
  token_type: string;
  id_token: string;
  expires_in: number;
};
export type RefreshToken = Omit<RawToken, 'refresh_token'>;
export type Token = RawToken & {
  expiry_date?: string;
};
// ---------- Token ----------
export const getSavedToken = (): Token | undefined => getLocalStorage(localStorageKeys.token);
export const saveToken = (t: RawToken) => {
  const expiresDate = dayjs().add(t.expires_in, 'second').toString();
  const token = { ...t, expiry_date: expiresDate };
  setLocalStorage(localStorageKeys.token, token);
};
export const removeToken = () => {
  localStorage.removeItem(localStorageKeys.token);
  removeUser();
};

// ---------- User ----------
export const getSavedUser = (): User | undefined => getLocalStorage(localStorageKeys.user);
export const saveUser = (user: User) => {
  setLocalStorage(localStorageKeys.user, user);
};
const removeUser = () => localStorage.removeItem(localStorageKeys.user);
