import { IWorkforceForecast } from '__mocks__/wffs';
import { IOrderBy, Order, STATISTICS_TYPE } from './WorkForceForecast';

export const calculateBackgroundColor = (
  value: number | null,
  isEnding: boolean | null,
  isClicked: boolean,
  isTotal: boolean,
) => {
  if (isClicked) return 'var(--symphony-purple)';
  if (isEnding) return 'var(--light-red)';
  if (value === null || isEnding === null) return 'none';
  if (isTotal) return 'var(--light-grey)';
  return value > 0 ? 'var(--light-green)' : 'var(--light-orange)';
};

const descendingComparator = (
  a: IWorkforceForecast,
  b: IWorkforceForecast,
  orderData: IOrderBy,
) => {
  const column = orderData.column as STATISTICS_TYPE;
  const dataIndex = orderData.index;
  if (
    b.data[dataIndex].month.allocated[column].value! <
    a.data[dataIndex].month.allocated[column].value!
  ) {
    return -1;
  }
  if (
    b.data[dataIndex].month.allocated[column].value! >
    a.data[dataIndex].month.allocated[column].value!
  ) {
    return 1;
  }
  return 0;
};

export function getComparator(
  order: Order,
  orderData: IOrderBy,
): (a: IWorkforceForecast, b: IWorkforceForecast) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderData)
    : (a, b) => -descendingComparator(a, b, orderData);
}

export function primitiveDataComparator(
  order: Order,
  orderData: IOrderBy,
): (a: IWorkforceForecast, b: IWorkforceForecast) => number {
  const attribute = orderData.column as keyof IWorkforceForecast;

  if (order === 'desc') {
    return (a, b) => (b[attribute] < a[attribute] ? -1 : 1);
  } else {
    return (a, b) => (b[attribute] > a[attribute] ? -1 : 1);
  }
}
