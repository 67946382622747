import { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Breadcrumbs } from '@mui/material';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { APP_ROUTES } from 'routes/paths';
import { General } from './general/General';
import { AssociatedAccounts } from './associatedAccounts/AssociatedAccounts';
import { KeyPeopleInPortfolio } from './keyPeopleInPortfolio/KeyPeopleInPortfolio';

export const Portfolio = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [value, setValue] = useState('1');

  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <div>
      <Breadcrumbs aria-label='breadcrumb' separator={<NavigateNextIcon fontSize='small' />}>
        <Link
          underline='hover'
          color='primary'
          style={{ cursor: 'pointer' }}
          onClick={() => {
            navigate(APP_ROUTES.BASE);
          }}
        >
          Home
        </Link>
        <Typography>Business</Typography>
        <Typography>Portfolios</Typography>
        {/* replace this ID with name from from real dataset */}
        <Typography color='text.primary'>{id}</Typography>
      </Breadcrumbs>
      <TabContext value={value}>
        <Box mt={'100px'}>
          <TabList
            onChange={handleChange}
            aria-label='lab API tabs example'
            textColor='primary'
            indicatorColor='primary'
          >
            <Tab value='1' label='General' />
            <Tab value='2' label='Accounts & Projects' />
            <Tab value='4' label='Key People in Portfolio' />
          </TabList>
        </Box>
        <TabPanel value='1' sx={{ padding: '24px 0px' }}>
          <General />
        </TabPanel>
        <TabPanel value='2' sx={{ padding: '24px 0px' }}>
          <AssociatedAccounts />
        </TabPanel>
        <TabPanel value='4' sx={{ padding: '24px 0px' }}>
          <KeyPeopleInPortfolio />
        </TabPanel>
      </TabContext>
    </div>
  );
};
