import { Navigate, useLocation, Outlet } from 'react-router-dom';
import Unauthorized from 'pages/unaouthorized/Unauthorized';
import { useAuth } from 'provider/AuthProvider';
import { PERMISSIONS } from 'utils/user';
import { APP_ROUTES } from './paths';

interface IAuthorization {
  permissions: PERMISSIONS[];
}

const Authorization = ({ permissions }: IAuthorization) => {
  const { user } = useAuth();
  const location = useLocation();

  if (user.username) {
    const userPermission = user.permissions;
    const isAllowed = permissions.some((allowed) => userPermission?.includes(allowed));
    return isAllowed ? <Outlet /> : <Unauthorized />;
  }

  return <Navigate to={APP_ROUTES.LOGIN} state={{ path: location.pathname }} replace />;
};

export default Authorization;
